/* global document */

// import ready from 'Utils/documentReady.js';

// ready(function() {
//   
// });

import $ from 'jquery';
import slick from 'slick-carousel';

$(function() {
    let slider =  $('.partners-slider')

    if(slider[0] != undefined && slider.children().length > 1){
        slider.slick({
            infinite: true,
            autoplay: true,
            autoplaySpeed: 3500,
            pauseOnHover: false,
            pauseOnFocus: false,
            speed: 1500,
            slidesToShow: 5,
            slidesToScroll: 3,
            dots: false,
            arrows: true,
            rows: 0,
            responsive: [
                {
                    breakpoint: 1024,
                    settings: {
                        slidesToShow: 4,
                        slidesToScroll: 2,
                    }
                },
                {
                  breakpoint: 768,
                  settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                  }
                },
                {
                  breakpoint: 480,
                  settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2
                  }
                }
              ]
        });
    }
});
