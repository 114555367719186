// /* global document console */

import ready from 'Utils/documentReady.js';
// import getScrollSize from 'Utils/getScrollSize.js';

ready(function() {
    let cities = document.querySelector('.cities__items'),
        citiesPoint = document.querySelector('.cities__point');

    if(cities){
        cities.addEventListener('mouseover', function(e){
            if(e.target.classList.contains('cities__item')) citiesPoint.textContent = e.target.textContent;
        })
    }
});

//import $ from 'jquery';
//import slick from 'slick-carousel';

