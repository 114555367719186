
/*!*
 * ВНИМАНИЕ! Этот файл генерируется автоматически.
 * Любые изменения этого файла будут потеряны при следующей компиляции.
 * Любое изменение проекта без возможности компиляции ДОЛЬШЕ И ДОРОЖЕ в 2-5 раз.
 */

/* global require */

require('../blocks/form-validation/form-validation.js');
require('../blocks/burger/burger.js');
require('../blocks/field-file/field-file.js');
require('../blocks/field-select/field-select.js');
require('../blocks/field-text/field-text.js');
require('../blocks/main-nav/main-nav.js');
require('../blocks/modal/modal.js');
require('../blocks/partners-slider/partners-slider.js');
require('../blocks/top-slider/top-slider.js');
require('./script.js');

/*!*
 * ВНИМАНИЕ! Этот файл генерируется автоматически.
 * Любые изменения этого файла будут потеряны при следующей компиляции.
 * Любое изменение проекта без возможности компиляции ДОЛЬШЕ И ДОРОЖЕ в 2-5 раз.
 */

