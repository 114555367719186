/* global document */

import ready from 'Utils/documentReady.js';

ready(function(){

  var burgers = document.querySelectorAll('.burger');
  var blackWrapper = document.querySelector('.black-wrapper');
  var body = document.querySelector('body');

  for (var i = 0; i < burgers.length; i++) {
    var burger = burgers[i];
    burger.addEventListener('click', showBurgerTarget);
  }

  function showBurgerTarget() {
    var targetId = this.getAttribute('data-target-id');
    var targetClassToggle = this.getAttribute('data-target-class-toggle');
    if (targetId && targetClassToggle) {
      this.classList.toggle('burger--close');
      document.getElementById(targetId).classList.toggle(targetClassToggle);
      blackWrapper.classList.toggle('active');
      body.classList.toggle('scroll-off');
    }
  }

});
