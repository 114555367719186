/* global document */

// import ready from 'Utils/documentReady.js';

// ready(function() {
//   
// });

import $ from 'jquery';
import slick from 'slick-carousel';

let topSlider =  $('.top-slider')

if(topSlider[0] != undefined && topSlider.children().length > 1){
    topSlider.slick({
        infinite: false,
        autoplay: true,
        autoplaySpeed: 6500,
        pauseOnHover: false,
        pauseOnFocus: true,
        speed: 2000,
        slidesToShow: 1,
        slidesToScroll: 1,
        dots: true,
        arrows: false,
        fade: true,
        rows: 0
    });
}